import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Base } from '@app/core/base/base';
import { Router } from '@angular/router';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { environment } from '@environments/environment';
@Component({
  selector: 'app-flow-end',
  templateUrl: './flow-end.component.html',
  styleUrls: ['./flow-end.component.scss'],
})
export class FlowEndComponent extends Base {
  @Input() translationRequired = true;
  @Input() message!: string;
  @Input() actionBtnText!: string;
  @Input() finalView!: string;
  @Input() deleteAccount = false;
  @Input() idvonly = false;
  @Output() flowend = new EventEmitter();

  constructor(  private readonly router: Router,) {

    
    super();
    window.console.log('deleteAccount', this.deleteAccount)
    if (!environment.module_auth) { this.idvonly = true}
  }

  onActionBtnClick() {
    if (this.finalView) {
      this.viewchange.emit(this.finalView);
    } else {
      this.flowend.emit();
    }    
  }
  navigateHome(){
    this.router.navigateByUrl(SDP_CONSTANTS.ROUTES.HOME);
  }
  

}
