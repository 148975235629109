import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Input,
  ViewChild,
} from '@angular/core';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { HidAuthenticationService,  HidDeviceDataService, HidRmsService, AuthService, SharedService  } from '@app/core/services';
import {
  CircleProgressComponent,
  CircleProgressOptions,
} from 'ng-circle-progress';
import { Subscription } from 'rxjs';
import {jwtDecode} from 'jwt-decode';
import { HIDApproveClientApprovalStatus } from '@app/core/helpers/enums';
import { Base } from '@app/core/base/base';
import { TranslateService } from '@ngx-translate/core';
import { HIDAuthServiceCallbackEvent } from '@app/core/models/hid-auth-service-callback-event';
import { environment } from '@environments/environment';
const CIRCLE_PROGRESS_OPTIONS = {
  radius: 40,
  space: -10,
  outerStrokeGradient: false,
  outerStrokeWidth: 10,
  outerStrokeColor: '#002d56',
  outerStrokeGradientStopColor: '#53a9ff',
  innerStrokeColor: '#bbbcbf',
  innerStrokeWidth: 10,
  animateTitle: false,
  animationDuration: 1000,
  showUnits: false,
  showBackground: false,
  clockwise: true,
  startFromZero: 0,
  showSubtitle: false,
  outerStrokeLinecap: 'butt',
};
import {  LoaderService} from '@app/core/services'
@Component({
  selector: 'app-hid-approve-push',
  templateUrl: './hid-approve-push.component.html',
  styleUrls: ['./hid-approve-push.component.scss'],
})
export class HidApprovePushComponent
  extends Base
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() user!: any;
  @Input() translationRequired = true;
  @Input() message!: string;
  @Input() pushMessage!: string;
  @Input() backBtn = true;
  @Input() cancelBtn!: boolean;
  @Input() transactionSigning = false;
  @Input() login!: boolean;
  @Output() showhidapprovetargetview = new EventEmitter();
  @Output() rejected = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  @ViewChild('circleProgress') circleProgress!: CircleProgressComponent;

  // Options property, contains the configuration for circle progress component
  circleProgressOptions!: CircleProgressOptions;

  // Time counter string shown on the component view
  title = '';

  // Timer
  timer!: any;
  timeout!: any;
  timerCheck!: any;
  // To show the progress in the circle progress component
  percent = 0;

  // Seconds counter for showing the progress. Initial value is 1 minute
  totalTimeInSeconds = 1 * 60;
  


  // Contains auth_req_id used to validate the right user's response with right request
  hidApproveAuthRequestId!: string;

  constructor(
   
    private readonly translateService: TranslateService,
    private readonly hidAuthService: HidAuthenticationService,
    private readonly hidDeviceDataService: HidDeviceDataService,
    private readonly hidRmsService: HidRmsService,
    private readonly authService: AuthService,
    private readonly sharedService: SharedService,
    private readonly loaderService: LoaderService,
  ) {
    super();
  }

  ngOnInit(): void {
   
    if (this.login) { this.pushMessage = 'Please approve your login to MajorBank web portal as ' + this.user?.externalId }
    

    this.sendPushNotification();
  }

  ngAfterViewInit() {
    this.circleProgressOptions = Object.assign(
      {},
      this.circleProgress.defaultOptions,
      CIRCLE_PROGRESS_OPTIONS,
    );
  }

  sendPushNotification() {
    this.hidAuthService.sendPushNotification(
      this.pushMessage,
      this._sendPushNotificationSuccess.bind(this),
    );
  }

  resendPushNotification() {
    this.error$$.next('');
   
    this.sendPushNotification();
  }

  private _sendPushNotificationSuccess(response: any) {
    this.hidApproveAuthRequestId = response['auth_req_id'];
    this.loaderService.hide()
   
    

    this._initiateCallbackCheck();

    this.timerCheck = setInterval(()=>this._initiateCallbackCheck(),2000)
    this.timeout = setTimeout(()=> this._closeCallback(), 63000)

    this._initTimer();
    this._startTimer();
  }

  private _closeCallback(){    
     this.hidDeviceDataService.selectedDevice = ''
    clearInterval(this.timer);
  }

  private _initTimer() {
    this.timer = undefined;
    this.percent = 0;
    this.totalTimeInSeconds = 1 * 60;
  }

  private _startTimer() {
    this.timer = setInterval(this._updateCountdown.bind(this), 1000);
  }

  private _updateCountdown() {
    const minutes = Math.floor(this.totalTimeInSeconds / 60);
    let seconds = this.totalTimeInSeconds % 60;

    if (seconds.toString().length === 1) {
      this.title = `${minutes}:0${seconds}`;
    } else {
      this.title = `${minutes}:${seconds}`;
    }

    if (this.totalTimeInSeconds === 60) {
      this.percent = 0;
    } else {
      this.percent = ((60 - this.totalTimeInSeconds) / 60) * 100;
    }

    this.totalTimeInSeconds--;

    if (this.percent === 100) {
      this.hidDeviceDataService.selectedDevice = ''
      this.hidAuthService.cancelPushNotification(this.hidApproveAuthRequestId, ()=>{}, ()=>{})
      clearInterval(this.timerCheck)
      clearInterval(this.timer);
      this._initTimer();
      this.error$$.next(
        this.translateService.instant('hidApprovePush.timeout'),
      );
   
    }
  }

  onUseSecureCodeClick() {
    if (this.transactionSigning) {
      this.viewchange.emit(SDP_CONSTANTS.VIEWS.HID_APPROVE_TRANS_OTP);
    } else {
      this.viewchange.emit(SDP_CONSTANTS.VIEWS.HID_APPROVE_OTP);
    }
  }

  onBackClick() {
     this.hidDeviceDataService.selectedDevice = ''
    this.hidAuthService.cancelPushNotification(this.hidApproveAuthRequestId, ()=>{}, ()=>{})
    clearInterval(this.timerCheck)
    clearInterval(this.timer);
    this.viewchange.emit(SDP_CONSTANTS.VIEWS.LOGIN);
  }

  onCancelClick() {
     this.hidDeviceDataService.selectedDevice = ''
    this.hidAuthService.cancelPushNotification(this.hidApproveAuthRequestId, ()=>{}, ()=>{})
    clearInterval(this.timerCheck)
    clearInterval(this.timer);
    this.cancelled.emit();
  }  

  private _rmsPT(){
    const nowu = this.hidAuthService.getUser()
    this.hidRmsService.loginSuccess(nowu.userName,'authentication_scheme',this.authService.getVisit()?.tttt, this._rmsDataYes.bind(this))  
   }


  private _rmsDataYes(){ 
    this.showhidapprovetargetview.emit();
  }

  private _rmsDataNo(){ 
    this.rejected.emit();
  }


  private _initiateCallbackCheck(){ 
   
      this.hidDeviceDataService.checkCallback(this.hidApproveAuthRequestId).subscribe((response: any) =>{
          if (response) {
       
            this.hidDeviceDataService.doneCallback(this.hidApproveAuthRequestId).subscribe((response1: any) =>{
              clearInterval(this.timerCheck)
              clearInterval(this.timer);
              
              const res = jwtDecode(response['id_token']);
            
              if (
                (res as any).clientapprovalstatus ===
                HIDApproveClientApprovalStatus.ACCEPT
              ) {
                if (environment.module_rms && !this.transactionSigning && this.login) {      
                 
                  const nowu = this.hidAuthService.getUser()
                  this.hidRmsService.loginStep(nowu.userName,2,'step_accepted', nowu.userInternalId, 'mtoken' ,this.authService.getVisit()?.tttt, this._rmsPT.bind(this))             
                    
                } else { this.showhidapprovetargetview.emit(); }
               
              } else {
                if (environment.module_rms && !this.transactionSigning && this.login) {
                const nowu = this.hidAuthService.getUser()
                this.hidRmsService.loginStep(nowu.userName,2,'login_denied', nowu.userInternalId,'mtoken',this.authService.getVisit()?.tttt, this._rmsDataNo.bind(this)) 
              } else { this.rejected.emit(); }
              }     


              
            })
          }
        })
      
  

  }



  ngOnDestroy() {
     this.hidDeviceDataService.selectedDevice = ''
   this.hidAuthService.cancelPushNotification(this.hidApproveAuthRequestId, ()=>{}, ()=>{})
    clearInterval(this.timerCheck)
    clearInterval(this.timer);
   
 
  }
}
