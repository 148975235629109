<!-- mfva2.component.html -->
 <!-- main control-->



 <!-- this.viewFormat - What shape of MFA -->
<ng-container *ngIf="viewFormat == 'full'">
  <div class="sdp-onboarding-comp-container no-shadows">


    <div class="sdp-onboarding-comp">

    
    <div> 
      <ng-container *ngTemplateOutlet="MFAHeader"></ng-container>
     </div>
      <div>   
          <ng-container *ngTemplateOutlet="MFAMainWindow"></ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="viewFormat == 'embed'">
  <ng-container *ngTemplateOutlet="MFAMainWindow"></ng-container>
</ng-container>

<ng-container *ngIf="viewFormat == 'popup'">
    <!-- TODO DIVS for POPUP overlay (transparent overlay) -->
    <ng-container *ngTemplateOutlet="MFAMainWindow"></ng-container>
</ng-container>

<ng-container *ngIf="viewFormat == 'overlay'">
    <!-- TODO DIVS for POPUP overlay (opaque overlay) -->
    <ng-container *ngTemplateOutlet="MFAMainWindow"></ng-container>
</ng-container>


<!-- this.viewHeader = MAIN MFA Web Site Header to allow for themes -->
<ng-template #MFAHeader>
  <ng-container *ngIf="viewHeader">
    <div class="sdp-onboarding-comp-header">
      <div
      src=""
      class="majorbank-logo-blue brand-logo"
     
      ></div>
      <div class="sdp-onboarding-comp-header-title ml-12">
        {{ 'majorBankTitle' | translate }}
      </div>
    </div>
  </ng-container>      
</ng-template>

<ng-template #IDVHeader>
  <ng-container *ngIf="viewHeader">
    <div class="sdp-onboarding-comp-header">
      <div
      src=""
      class="hid-logo brand-logo"
     
      ></div>
      <div class="sdp-onboarding-comp-header-title ml-12">
        HID IDV Demonstration
      </div>
    </div>
  </ng-container>      
</ng-template>


<!--MAIN MFA Window Template Mapping By UseCase To Handle User Journey -->
<ng-template #MFAMainWindow>  

  <ng-container [ngSwitch]="UseCase">    
<!-- this.UseCase come from SDP_CONSTANT.MFAV2_USECASES -->
  <ng-container *ngSwitchCase="SDP_CONSTANTS.MFAV2_USECASES.SEARCH_USER">
      <ng-container *ngTemplateOutlet="SearchUserTemplate"></ng-container>
  </ng-container>
  
  <ng-container *ngSwitchCase="SDP_CONSTANTS.MFAV2_USECASES.ADD_DEVICE">    
      <app-add-device
        #addDevice
        [standalone]="false"
        (flowtype)="onFlowType($event)"
        (viewchange)="onViewChange($event)"
        (adddeviceviewchange)="onAddDeviceViewChange($event)"
      ></app-add-device>


  </ng-container>

  <ng-container *ngSwitchCase="SDP_CONSTANTS.MFAV2_USECASES.FACTOR1">
    <ng-container *ngTemplateOutlet="Factro1Template"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="SDP_CONSTANTS.MFAV2_USECASES.ONBOARDING">
    <ng-container *ngTemplateOutlet="ChooseOnboardingTemplate"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="SDP_CONSTANTS.MFAV2_USECASES.DIGITAL_ONBOARDING">
    <ng-container *ngTemplateOutlet="OnboardingDigitalTemplate"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="SDP_CONSTANTS.MFAV2_USECASES.CREATE_PASSWORD">
    <ng-container *ngTemplateOutlet="CreatePasswordTemplate"></ng-container>
  </ng-container>


  <ng-container *ngSwitchCase="SDP_CONSTANTS.MFAV2_USECASES.HID_APPROVE_PUSH">
    <ng-container *ngTemplateOutlet="HIDApproveTemplate"></ng-container>
  </ng-container>


  <ng-container *ngSwitchCase="SDP_CONSTANTS.MFAV2_USECASES.HID_APPROVE_CODE">
    <ng-container *ngTemplateOutlet="HIDApproveTemplate"></ng-container>
  </ng-container> 


  <ng-container *ngSwitchCase="SDP_CONSTANTS.MFAV2_USECASES.FIDO">
   <ng-container *ngTemplateOutlet="FIDOUseTemplate"></ng-container>
  </ng-container> 

  <ng-container *ngSwitchCase="SDP_CONSTANTS.MFAV2_USECASES.FACTOR2">
    <ng-container *ngTemplateOutlet="Factor2Template"></ng-container>
  </ng-container>


  <ng-container *ngSwitchCase="SDP_CONSTANTS.MFAV2_USECASES.MANUAL_REGISTER">
    <ng-container *ngTemplateOutlet="OnboardingManualTemplate"></ng-container>
  </ng-container>

 <ng-container *ngSwitchCase="SDP_CONSTANTS.MFAV2_USECASES.FLOW_END">
    <ng-container *ngTemplateOutlet="FlowEndTemplate"></ng-container>
  </ng-container> 


  <ng-container *ngSwitchCase="SDP_CONSTANTS.MFAV2_USECASES.FORGOT_PASSWORD_OTP">    
    <ng-container *ngTemplateOutlet="ForgotPassOTPTemplate"></ng-container>
  </ng-container>
  
    <ng-container *ngSwitchDefault>          
      <ng-container *ngTemplateOutlet="SearchUserTemplate"></ng-container>
    </ng-container>

  </ng-container>
</ng-template>



<!-- CONTENT -->
<ng-template #SearchUserTemplate >
  <form [formGroup]="form" class="securedLogForm" (ngSubmit)="SearchUser()">
    <ng-container *ngIf="!QRResponse">
    <div class="sdp-ob-form-header">
      <p> 
        {{ 'searchUser.title2' | translate }}
      </p> 
      <p class="sdp-ob-intro">
        {{ 'searchUser.title' | translate }}
       
      </p>
    </div>
  
    <div class="form-group">
      <!-- <label for="email">{{ 'searchUser.emailAddress' | translate }}</label> -->
      <input
        id="email"
        type="email"
        class="form-control securedLogIdent"
        formControlName="email"
        placeholder="Your email address"
        required
        maxlength="100"
      />
      <app-form-control-msg
        [control]="form.controls.email"
        [formSubmitted]="submitted"
      ></app-form-control-msg>
    </div>
    <br>
    
    <div class="form-group d-flex justify-content-around">
      <button type="submit" class="btn btn-primary-mb securedLogSubmit" [disabled]="form.invalid">
        {{ 'flowEnd.continue' | translate }}
      </button>
  
  
    </div>
  
  
    <ng-container *ngIf="!isMobile">
  
    <br>
    <hr class="hr hr-blurry" />
    <br />
   
    <br />
  
   
    
    <div class="sdp-ob-form-header">    
      <p> 
        {{ 'searchUser.qrTitle2' | translate }}
      </p>    
      <p class="sdp-ob-intro"> 
        {{ 'searchUser.qrTitle' | translate }}
      </p> 
    </div> 
    
    
    <ng-container *ngIf="qrCodeData">
    
    
        <div class="qr-form d-flex justify-content-center">
            <div class="qrcode-holder">
             
                  <qrcode 
                  [qrdata]="qrCodeData"    
                  [colorDark]="'#3f51b5'"
                  [colorLight]="'#ffffff'" 
                  [elementType]="'img'"
                  [errorCorrectionLevel]="'H'"              
                  [cssClass]="'qrCodeClass'"
                  (click)="S2A.onToggleQRTypeDialogue()"
                  [width]="350"
                  ></qrcode>
    
           
            </div>
            <br/>
            <br/>
           
            </div>
            <ng-container *ngIf="QRTypeDialogue">
  
  
              
            <div class="qr-special qr-form d-flex justify-content-center">            
                <span class="qrSelectorButton">               
                  <ng-container *ngIf=qrImplicit>
                    <a (click)="S2A.onToggleQRType()"><span class="left-span "> Using <b>Implicit</b> Approval. <br /> Click <b>here</b> to try Explicit.</span></a>
                  </ng-container> 
                  <ng-container *ngIf=!qrImplicit>
                    <a (click)="S2A.onToggleQRType()"><span class="right-span"> Using <b>Explicit</b> Approval. <br />  Click <b>here</b> to try Implicit.</span></a>
                  </ng-container> 
                </span>			
              </div>   
                
  
  
  
  
                
              </ng-container>      
       
            
          </ng-container>
    
    
          <ng-container *ngIf="!qrCodeData">
            <!-- Loading
            <div class="qrcode-holder">
             
              <qrcode 
              qrdata="https://www.hidglobal.com/products/hid-approve"    
              [colorDark]="'#3f51b5'"
              [colorLight]="'#ffffff'" 
              [elementType]="'img'"
              [errorCorrectionLevel]="'M'"              
              [cssClass]="'qrimg'" 
              ></qrcode>
    
       
        </div> -->
          </ng-container>
  
        </ng-container>
      </ng-container>
      
      <ng-container *ngIf="QRResponse">
  
        <div class="sdp-ob-form-header">    
          <p> 
            {{ 'searchUser.qrTitle2' | translate }}
          </p>    
          <p class="sdp-ob-intro"> 
            {{ 'searchUser.qrAuthentication' | translate }}
          </p> 
  
  
  
          <a 
          class="                
          w-100 
          btn btn-primary-mb btn-lg 
          d-flex align-items-center justify-content-center
          "
          [href]="" 
                   
          style="height: 100%;"
          (click)="
              goToBank()
          "
          >
           
          <span>{{ 'searchUser.qrAuthenticationFallback' | translate }}</span>
        </a>
  
  
  
        </div> 
      </ng-container>
  </form>
</ng-template>

<ng-template #Factro1Template>


  
  <!-- this should be just password template -->
  <form [formGroup]="form" class="securedLogForm" (ngSubmit)="this.Factor1.onSubmit()" >
    <div class="sdp-ob-form-header">
      <h3 class="sdp-ob-title">{{ 'login.title' | translate }}</h3>
    </div>
  
    <div class="form-group">
      <label for="userName">{{ 'login.userName' | translate }}</label>
      <input
        id="userName"
        type="text"
        class="form-control securedLogIdent"
        formControlName="userName"
        autoComplete="username"
        required
        maxlength="100"
        readonly
      />
      <app-form-control-msg
        [control]="form.controls.userName"
        [formSubmitted]="submitted"
      ></app-form-control-msg>
    </div>
  
    <div class="form-group position-relative">
      <app-toggle-view-password
        [passwordEl]="passwordEl"
      ></app-toggle-view-password>
      <label for="password">{{
        (newUser ? 'login.temporaryPassword' : 'login.password') | translate
      }}</label>
      <input
        id="password"
        type="password"
        class="form-control securedLogPassword"
        formControlName="password"
        autoComplete="current-password"
        required
        maxlength="100"
        #passwordEl
      />
      <app-form-control-msg
        [control]="form.controls.password"
        [formSubmitted]="submitted"
      ></app-form-control-msg>
    </div>
  
    <div class="text-danger" *ngIf="error$$ | async as error">
      <small>{{ error }}</small>
    </div>
  
    <div class="form-group sdp-ob-intro">
      <ng-container *ngIf="!newUser">
        <a class="sdp-dummy-link-ul" (click)="this.Factor1.onForgotPassword()">
          {{ 'searchUser.forgotPassword' | translate }}
        </a>
      </ng-container>
  
      <ng-container *ngIf="newUser">
        <div class="mt-1">
          {{ 'forgotPasswordOtp.noTempPwd' | translate }}
          <a class="sdp-dummy-link-ul" (click)="this.Factor1.sendOTP()">{{
            'clickHere' | translate
          }}</a
          >{{ 'forgotPasswordOtp.resend' | translate }}
          <br />
          {{ 'forgotPasswordOtp.delete' | translate }}
          <a class="sdp-dummy-link-ul" (click)="deleteUser()">{{
            'clickHere' | translate
          }}</a
          >
          <br />
        
        </div>
      </ng-container>
    </div>
  
    <div class="form-group text-right">
      <button
        type="button"
        class="btn btn-outline-primary mr-3"
        (click)="onCancel()"
      >
        {{ 'cancel' | translate }}
      </button>
      <button type="submit" class="btn btn-primary-mb securedLogSubmit" [disabled]="form.invalid">
        {{ (newUser ? 'signUp' : 'loginBtnText') | translate }}
      </button>
     
    </div>
  </form>
  <!-- there should be fido auth template -->
  <!-- there should be fido  -->
  <!-- disabling for not seeing this good for banking -->

  <!-- <ng-container *ngTemplateOutlet="FIDOUseTemplate"></ng-container> -->
</ng-template>

<ng-template #ChooseOnboardingTemplate>
  <form [formGroup]="form" (submit)="Onboarding()">
    <div class="sdp-ob-form-header">
      <h3 class="sdp-ob-title">
        {{ 'selectOnbordingProcess.title' | translate }}
      </h3>
    </div>
  
    <div class="form-container">
      <div *ngIf="(idvenabled)" class="form-check">
        <label class="form-check-label" for="digitalonboarding">
          <input
            class="form-check-input"
            type="radio"
            name="registration"
            id="digitalonboarding"
            value="digitalonboarding"
            checked
            formControlName="registration"
          />
          {{ 'selectOnbordingProcess.digital' | translate }}</label
        >
      </div>
    
  
      <div class="form-check">
        <label class="form-check-label" for="manualonboarding">
          <input
            class="form-check-input"
            type="radio"
            name="registration"
            id="manualonboarding"
            value="manualonboarding"
            formControlName="registration"
          />
          {{ 'selectOnbordingProcess.manual' | translate }}</label
        >
      </div>
  
      <div class="text-danger" *ngIf="error$$ | async as error">
        <small>{{ error }}</small>
      </div>
    </div>
  
    <div class="form-group text-right">
      <button type="submit" class="btn btn-primary-mb" [disabled]="form.invalid">
        {{ 'confirm' | translate }}
      </button>
    </div>
  </form>
</ng-template>

<ng-template #OnboardingDigitalTemplate>

    <app-component-onboarding-idv
      (idvRedirecturi)="idvRedirecturi"
      (idvRedirectrequestID)="idvRedirectrequestID"
      (viewchange)="onViewChange($event)"
    ></app-component-onboarding-idv>

</ng-template>

<ng-template #CreatePasswordTemplate>

  <form [formGroup]="form" class="securedLogForm" (ngSubmit)="CreatePassword.onSubmit()">
    <div class="sdp-ob-form-header">
      <h3 class="sdp-ob-title">{{ 'createPassword.title' | translate }}</h3>
      <p class="sdp-ob-intro">
        {{ 'createPassword.description' | translate }}
        <span
          class="sdp-dummy-link d-inline-block"
          [tooltip]="passwordGuidelinesTpl"
          container="body"
          containerClass="sdp-pwd-guidelines"
          >{{ 'createPassword.reviewPassword' | translate }}&nbsp;<i
          class="bi bi-question-circle-fill"
          ></i
        ></span>
      </p>
    </div>
  
    <div class="form-group position-relative">
      <app-toggle-view-password
        [passwordEl]="passwordEl"
      ></app-toggle-view-password>
      <label for="passwordCreate">{{
        'createPassword.createPassword' | translate
      }}</label>
      <input
        id="passwordCreate"
        type="password"
        class="form-control securedLogPassword"
        formControlName="passwordCreate"
        [maxLength]="passwordPolicy?.maxLength"
        #passwordEl
        (input)="CreatePassword.validate(passwordEl.value)"
      />
  
      <ng-container *ngIf="passwordCtrl?.valid">
        <ng-container *ngFor="let policyMap of policy.policyMap | keyvalue">
          <div class="sdp-pwd-validation-wrapper">
            <ng-container
              *ngIf="
                $any(policyMap.value).valid !== undefined &&
                $any(policyMap.value).valid
              "
            >
            <i class="bi bi-padding bi-check-circle-fill"></i>
            </ng-container>
  
            <ng-container
              *ngIf="
                $any(policyMap.value).valid !== undefined &&
                !$any(policyMap.value).valid
              "
            >
              <i class="bi bi-padding bi-x-circle-fill"></i>
            </ng-container>
  
            <ng-container *ngIf="$any(policyMap.value).valid !== undefined">
              <div
                [ngClass]="
                  $any(policyMap.value).valid ? 'status match' : 'status unmatch'
                "
              >
                {{ $any(policyMap.value).msg }}
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="passwordCtrl?.invalid">
        <app-form-control-msg
          [control]="form.controls.passwordCreate"
          [formSubmitted]="submitted"
        ></app-form-control-msg>
      </ng-container>
    </div>
  
    <div class="form-group position-relative">
      <app-toggle-view-password
        [passwordEl]="confirmPassword"
      ></app-toggle-view-password>
      <label for="currentPassword">{{
        'createPassword.reenterPassword' | translate
      }}</label>
      <input
        id="confirmPassword"
        type="password"
        class="form-control securedLogPassword"
        formControlName="confirmPassword"
        [maxLength]="passwordPolicy?.maxLength"
        #confirmPassword
      />
      <app-form-control-msg
        [control]="form.controls.confirmPassword"
        [formSubmitted]="submitted"
      ></app-form-control-msg>
    </div>
  
    <ng-container
      *ngIf="
        form.controls.passwordCreate.valid &&
        form.controls.confirmPassword.valid &&
        form.errors?.mustMatch
      "
    >
      <div class="text-danger">
        <small>{{ 'validations.mustMatch' | translate }}</small>
      </div>
    </ng-container>
  
    <div class="form-group text-right">
      <button
        type="submit"
        class="btn btn-primary-mb securedLogSubmit"
        [disabled]="form.invalid || !CreatePassword.isPasswordValid()"
      >
        {{ 'submit' | translate }}
      </button>
    </div>
  </form>
  
  <ng-template #passwordGuidelinesTpl>
    <p>{{ 'createPassword.passwordGuidelines' | translate }}</p>
    <ol type="1">
      <li *ngFor="let guideline of policy.passwordGuidelines">{{ guideline }}</li>
    </ol>
  </ng-template>
  
   
</ng-template>

<ng-template #OnboardingManualTemplate>
  <mfav2-manual-registration
  (locemail)="useremail"
  (viewchange)="onViewChange($event)"
  (flowtype)="onFlowType($event)"
></mfav2-manual-registration>
   
</ng-template>

<ng-template #FlowEndTemplate>
  <app-flow-end
  [message]="flowEndMessage"
  [actionBtnText]="'flowEnd.login'"
  [finalView]="finalView"
  [deleteAccount]="deleteAccount"
  (viewchange)="onViewChange($event)"
  (flowend)="onFlowEnd()"
></app-flow-end>   
</ng-template>


<ng-template #DeviceSearchTempalte>
  <div class="sdp-device-search">
    <div class="text-center">
      <!-- DEVICE SEARCH -->

      <ng-container *ngIf="!noDeviceFound">
        <div class="lds-dual-ring"></div>
        <p>{{ 'hidApprove.deviceSearch' | translate }}</p>
      </ng-container>

      <!-- NO DEVICE FOUND -->

      <ng-container *ngIf="noDeviceFound">
        <span class="text-danger"
          >{{ 'hidApprove.noDeviceFoundLine1' | translate
          }}<a class="sdp-dummy-link-ul" (click)="deleteUser()">{{
            'clickHere' | translate | lowercase
          }}</a
          >{{ 'hidApprove.noDeviceFoundLine2' | translate }}</span
        >
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #ForgotPassOTPTemplate>
  <div class="sdp-ob-form-header">
    <h3 class="sdp-ob-title">
      {{ 'forgotPasswordOtp.secureCodeTitle' | translate }}
    </h3>
    <div class="sdp-ob-intro">
      {{ 'forgotPasswordOtp.secureCodeDescription' | translate }}
      <div class="mt-1">
        {{ 'forgotPasswordOtp.noSecureCode' | translate }}
        <a class="sdp-dummy-link-ul" (click)="ForgotPassword.sendOTP()">{{
          'clickHere' | translate
        }}</a
        >{{ 'forgotPasswordOtp.resend' | translate }}
      </div>
    </div>
  
    <div class="w-100 mt-4 mb-5">
      <div class="form-group text-left">
        <label for="otp">{{ 'forgotPasswordOtp.otp' | translate }}</label>
  
        <ng-otp-input
          appNgotpinputKeyEvent
          [submitBtn]="submitBtn"
          #ngOtpInput
          (onInputChange)="ForgotPassword.onOtpChange($event)"
          [config]="{
            length: SDP_CONSTANTS.OTP_FIELDS_LENGTH.OOBEML_OTP,
            allowNumbersOnly: true
          }"
        ></ng-otp-input>
  
        <div class="invalid-feedback d-block" *ngIf="incorrectOtp">
          {{ 'forgotPasswordOtp.incorrectOtp' | translate }}
        </div>
      </div>
    </div>
  </div>
  
  <div class="text-right">
    <button
      type="submit"
      class="btn btn-primary-mb"
      (click)="ForgotPassword.onSubmit()"
      [disabled]="
        ngOtpInput?.otpForm?.invalid ||
        otp?.length !== SDP_CONSTANTS.OTP_FIELDS_LENGTH.OOBEML_OTP
      "
      #submitBtn
    >
      {{ 'confirm' | translate }}
    </button>
  </div>
  
</ng-template>

<ng-template #Factor2Template> 
  <app-adaptive-authentication
  [factor]="2"
  [cancelBtn]="true"
  [loginAdaptive] = "true"
  (viewchange)="onViewChange($event)"
  (rejected)="onRejected()"
  (cancelled)= "onCancel()"
></app-adaptive-authentication>
</ng-template>


<ng-template #HIDApproveTemplate>
  <!-- <ng-container [ngSwitch]="activeView"> -->
    <ng-container [ngSwitch]="activeView">
    <!-- SELECT PRIMARY DEVICE -->

    <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.SELECT_PRIMARY_DEVICE">
      <app-select-primary-device
        (viewchange)="onViewChange($event)"
      ></app-select-primary-device>
    </ng-container>
    
    <!-- HID Approve Push -->

    <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.HID_APPROVE_PUSH">
      <app-hid-approve-push     
        [message]="viewSuccessScreenMessage"
        [login]="login"
        [user]="user"
        [pushMessage]="pushMessage"
        [backBtn]="viewBackBtn"
        [cancelBtn]="viewCancelBtn"
        [transactionSigning]="transactionSigning"
        (viewchange)="onViewChange($event)"
        (showhidapprovetargetview)="onShowHIDApproveTargetView()"
        (rejected)="onRejected()"
        (cancelled)="onCancel()"
      ></app-hid-approve-push>
    </ng-container>

    <!-- HID APPROVE OTP -->

    <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.HID_APPROVE_OTP">
      <app-hid-approve-otp
        (showhidapprovetargetview)="onShowHIDApproveTargetView()"
        (cancelled)="onCancel()"
        [secureCodeDesc]="secureCodeDesc"
        [cancelBtn]="viewCancelBtn"
      ></app-hid-approve-otp>
    </ng-container>

    <!-- HID APPROVE TRANSACTION SIGNING OTP -->

    <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.HID_APPROVE_TRANS_OTP">
      <app-transaction-signing-otp
        []
        [fundsTransferInfo]="fundsTransferInfo"
        (showhidapprovetargetview)="onShowHIDApproveTargetView()"
      ></app-transaction-signing-otp>
    </ng-container>

    <!-- FLOW END -->

    <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.FLOW_END">
      <app-flow-end
        [translationRequired]="translationRequired"
        [message]="viewSuccessScreenMessage"
        [actionBtnText]="viewSuccessScreenActionBtnText"
        [finalView]="finalView"
        (flowend)="onFlowEnd()"
      ></app-flow-end>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="DeviceSearchTempalte"></ng-container>
    </ng-container>
  </ng-container>
</ng-template> 


<ng-template #FIDOPopup>

</ng-template>

<ng-template #FIDORegisterTemplate>
      
</ng-template>

<ng-template #FIDOUseTemplate>
      
  <a class="sdp-dummy-link-ul" (click)="Fido._getChallenge()">    
    Fido._getChallenge
  </a>
  <a class="sdp-dummy-link-ul" (click)="Fido._getCredOptions()">    
    Fido._getCredOptions
  </a>
  <!-- <a class="sdp-dummy-link-ul" (click)="Fido.Login()">    
    Fido.Login
  </a>
  <a class="sdp-dummy-link-ul" (click)="Fido.Register()">    
    Fido.Register
  </a> -->
  <a class="sdp-dummy-link-ul" (click)="Fido.clean()">    
    Fido.clean
  </a>
  <a class="sdp-dummy-link-ul" (click)="Fido._registerVerify()">    
    Fido._registerVerify
  </a>
  <a class="sdp-dummy-link-ul" (click)="Fido._loginVerify()">    
    Fido._loginVerify
  </a>





</ng-template>